a {
  text-decoration: none;
  color: black;
}

body {
  background-color: #F4F4FA !important;
}

.appContainer {
  margin-top: 104px;
  padding: 16px;
}

.iiz__img {
  max-width: 100%;
  max-height: 500px;
  display: block;
  pointer-events: none;
  visibility: visible;
  opacity: 1;
  padding: 50px;
  background-color: rgba(0, 0, 0, 0.8);
}

.iiz__hint {
  display: none;
}