.appContainer {
  margin-top: 52px;
  padding: 8px;
}

.barMenuTitle a {
  color: rgb(207, 204, 204);
  text-decoration: none;
}

.cardAppContainer {
  background-color: #FFFFFF;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  margin-bottom: 32px !important;
  overflow: hidden;
  padding: 32px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.fabFloatingButton {
  bottom: 20px;
  left: auto;
  margin: 0px;
  position: fixed;
  right: 20px;
  top: auto;
  z-index: 100;
}

.formInput {
  margin-bottom: 8px !important;
  margin-Top: 8px;
  padding: 8px;
}

.header {
  color: #FFFFFF;
  margin: 16px;
  padding-left: 12px;
  padding-right: 12px;
}

.loadingContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.MuiTableCell-paddingCheckbox:last-child {
  padding-left: 16px !important;
}

.textTag {
  margin: 8px;
  width: 100%;
}

.formTextError {
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 8px;
  min-height: 1em;
  text-align: left;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: 0.03333em;
}

.MuiCardMedia-root {
  background-size: contain !important;
}

.MuiSpeedDial-actions {
  position: absolute !important;
}
.MuiFab-primary:hover {
  background-color: #d5d5d5 !important;
}